<!--确认支付页面-->
<template>
  <!-- 银行支付 -->
  <div class="pageF6ColorBox" v-if="userSelectedPaymentMethod != 5">
    <div class="paymentInfoBox">
      <img
        :src="require('@/assets/icon/bank1.png')"
        alt=""
        v-if="userSelectedPaymentMethod == 2"
        class="bankImg"
      />
      <img
        :src="require('@/assets/icon/bank2.png')"
        alt=""
        v-if="userSelectedPaymentMethod == 3"
        class="bankImg"
      />
      <img
        :src="require('@/assets/icon/bank3.png')"
        alt=""
        v-if="userSelectedPaymentMethod == 4"
        class="bankImg"
      />
      <div class="remittanceBank">
        <div class="left" v-if="userSelectedPaymentMethod == 2">
          <p>
            <span class="title">{{ $fanyi("銀行名") }}：</span>
            <span>楽天銀行</span>
          </p>
          <p>
            <span class="title">{{ $fanyi("支店番号") }}：</span>
            <span>252</span>
          </p>
          <p>
            <span class="title">{{ $fanyi("支店名") }}：</span>
            <span>第二営業支店</span>
          </p>
          <p>
            <span class="title">{{ $fanyi("預金種目") }}：</span>
            <span>普通預金</span>
          </p>
          <p>
            <span class="title">{{ $fanyi("口座番号") }}：</span>
            <span>7977668</span>
          </p>
          <p>
            <span class="title">{{ $fanyi("口座名義") }}：</span>
            <span>ラクマート（カ）</span>
          </p>
        </div>
        <div class="left" v-if="userSelectedPaymentMethod == 4">
          <p>
            <span class="title">{{ $fanyi("銀行名") }}：</span>
            <span>paypay銀行</span>
          </p>
          <p>
            <span class="title">{{ $fanyi("支店番号") }}：</span>
            <span> 005</span>
          </p>
          <p>
            <span class="title">{{ $fanyi("支店名") }}：</span>
            <span> ビジネス営業部</span>
          </p>
          <p>
            <span class="title">{{ $fanyi("預金種目") }}：</span>
            <span>普通預金</span>
          </p>
          <p>
            <span class="title">{{ $fanyi("口座番号") }}：</span>
            <span> 1928861</span>
          </p>
          <p>
            <span class="title">{{ $fanyi("口座名義") }}：</span>
            <span>ラクマート（カ）ジャパン</span>
          </p>
        </div>
        <div class="left" v-if="userSelectedPaymentMethod == 3">
          <p>
            <span class="title">{{ $fanyi("銀行名") }}：</span>
            <span> ゆうちょ銀行</span>
          </p>
          <p>
            <span class="title">{{ $fanyi("支店番号") }}：</span>
            <span>四〇八支店</span>
          </p>

          <p>
            <span class="title">{{ $fanyi("口座番号") }}：</span>
            <span>5631876</span>
          </p>
          <p>
            <span class="title">{{ $fanyi("口座名義") }}：</span>
            <span>ラクマート（カ）</span>
          </p>
          <p><span> ゆうちょ銀行からのお振込</span></p>
          <p><span>14040-56318761</span></p>
        </div>
        <button class="copyBrn" @click="copyInformation">
          {{ $fanyi("复制银行信息") }}
        </button>
      </div>
      <div class="depositInformation">
        <div class="minimumDepositAmount">
          <span class="title">{{ $fanyi("最低入金金额") }}：</span
          ><b>{{ paymentPagePreviewData.bank_pay_amount_min }}</b>
          <span class="danwei"> 円</span>
        </div>
        <van-form class="paymentFormBox">
          <van-cell-group inset>
            <van-field
              v-model="paymentForm.pay_reach_date"
              readonly
              name="pay_reach_date"
              label="入金日"
              @click="showCalendar = true"
            />
            <van-calendar
              :title="$fanyi('选择日期')"
              v-model:show="showCalendar"
              @confirm="onConfirm"
            />
            <van-field
              :label="`入金名義
カタカナ記載`"
              v-model="paymentForm.pay_name"
              name="pay_name"
              placeholder=""
            />
            <van-field
              label="入金额"
              v-model="paymentForm.pay_amount"
              name="pay_amount"
              placeholder=""
            >
              <template #input>
                <div class="paymentAmount van-cell">
                  <input
                    type="text"
                    oninput="value=value.replace(/[^0-9.]/g,'')"
                    v-model="paymentForm.pay_amount"
                    @change="amountInputRestriction(paymentForm, 'pay_amount')"
                  />
                  <span class="yuan">円</span>
                </div>
              </template>
            </van-field>
            <van-field
              label="備考欄"
              v-model="paymentForm.pay_remark"
              name="pay_remark"
              placeholder=""
              class="optionalField"
            >
              <template #input>
                <textarea v-model="paymentForm.pay_remark" />
              </template>
            </van-field>
          </van-cell-group>
        </van-form>
        <div class="RemittanceNotes">
          <h3>{{ $fanyi("注意") }}</h3>
          <p>１、銀行入金する金額を入力して下さい。</p>
          <p>
            ２、入金報告時（画面）に、入金額と入金報告額は一致させるようお願いします。複数注文の場合は、まず残高をチャージしてからお支払いを行うことをお勧めします。
          </p>
          <p>
            ３、入金名義について、必ず入金報告提出時と同じなるようにお願い致します。違う場合は特定出来ず、入金反映が著しく遅延してしまいます。
          </p>
          <p class="importantReminder">
            ※タイミングによっては為替変動により残高不足となります。
            その場合、注文書は実行されませんので、少々多めのご入金額設定をお勧め致します。】
          </p>
        </div>
      </div>
    </div>
    <div class="bankPayment">
      <button class="payBtn" @click="confirmBankPayment">
        {{
          ["2", "3", "4"].includes($route.query.userSelectedPaymentMethod)
            ? "提出"
            : "支払い"
        }}
      </button>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, computed, onMounted } from "vue";
import { getCurrentDay } from "@/utlis/date.js";
const { proxy } = getCurrentInstance();
//------------------------ data -----------------------------------------
const showCalendar = ref(false); //选择日期弹窗开关
// 支付方式
const userSelectedPaymentMethod = computed(() => {
  return (
    proxy.$route.query.userSelectedPaymentMethod ||
    proxy.$parent.userSelectedPaymentMethod
  );
});

// 支付预览数据
const paymentPagePreviewData = computed(() => {
  return proxy.$parent.paymentPagePreviewData;
});
//支付表单
const paymentForm = ref({
  pay_reach_date: getCurrentDay(), //入金日
  pay_amount: paymentPagePreviewData.value.bank_pay_amount_min, //入金额
  pay_remark: "", //備考欄
  pay_name: "", //入金名義
});

//------------------------ methods --------------------------------------
// 复制银行信息
const copyInformation = () => {
  let text = "";
  switch (proxy.$route.query.userSelectedPaymentMethod) {
    case "2":
      text = `
銀行名：楽天銀行
支店番号：252
支店名：第二営業支店
預金種目：普通預金
口座番号：7977668
口座名義：ラクマート（カ）
      `;
      break;
    case "3":
      text = `
銀行名：paypay銀行（旧）ジャパンネット銀行
支店番号：005
支店名：ビジネス営業部
預金種目：普通預金
口座番号：1928861
口座名義：ラクマート（カ）ジャパン

      `;
      break;
    case "4":
      text = `
銀行名：ゆうちょ銀行
支店名：四〇八支店
口座番号：5631876
口座名義：ラクマート（カ）ゆうちょ銀行からのお振込 14040-56318761
      `;
      break;
    default:
      break;
  }
  navigator.clipboard.writeText(text);
  proxy.$message.primary(proxy.$fanyi("复制成功"));
};
// 限制输入框只能输入数字
const amountInputRestriction = (data, name) => {
  if (isNaN(data[name])) {
    data[name] = 0;
    proxy.$message.warning("请输入数字");
  }
};
// 确认选择日期
const onConfirm = (val) => {
  paymentForm.value.pay_reach_date = getCurrentDay(val);
  showCalendar.value = false;
};
const emit = defineEmits(["recharge"]);
// 使用银行付款
const confirmBankPayment = () => {
  // console.log(proxy.$route.path);
  // 校验表单必填项
  let checklist = ["pay_reach_date", "pay_amount", "pay_name"];
  for (let i in checklist) {
    if (!paymentForm.value[checklist[i]]) {
      return proxy.$message.warning(proxy.$fanyi("请填写必填信息"));
    }
  }

  emit("recharge", paymentForm.value);
};
//------------------------ pageLoad -------------------------------------
setTimeout(() => {
  let userpayMethAccound = (
    proxy.$store.state.userInfo || { user_pay_account: [] }
  ).user_pay_account;
  userpayMethAccound.forEach((usPaAc) => {
    if (
      usPaAc.bill_method == proxy.$route.query.userSelectedPaymentMethod &&
      usPaAc.is_default == 1
    ) {
      paymentForm.value.pay_name = usPaAc.pay_account;
    }
  });
}, 100);
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.pageF6ColorBox {
  padding: 0px 30px;
  display: flex;
  flex-direction: column;
  min-height: unset !important;
}

.topUpAmount {
  margin-top: 20px;
  padding: 60px 0;
  background: #ffffff;
  border-radius: 6px;

  h5 {
    font-size: 24px;
    text-align: center;
    line-height: 1;
    color: #999999;
    margin-bottom: 29px;
  }

  p {
    text-align: center;
    font-size: 56px;
    color: #b4272b;
    font-weight: 600;

    span {
      font-size: 24px;
      font-weight: 400;
      margin-left: 8px;
    }
  }

  .paymentOrderNumber {
    margin-top: 29px;
    font-size: 24px;

    font-weight: 400;
    color: #000000;
    text-align: center;
    display: block;
  }
}

.accountBalanceInformation {
  margin-top: 15px;
  width: 690px;
  background: #ffffff;
  border-radius: 6px;
  padding: 30px;

  p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 42px;

    &:last-child {
      margin-bottom: 0;
    }

    .title {
      font-size: 28px;

      font-weight: 400;
      color: #999999;
    }

    .price {
      font-size: 28px;

      font-weight: 600;

      b {
        color: #b4272b;
        font-weight: 600;
        font-size: 28px;
      }
    }
  }
}
.bankPayment {
  position: sticky;
  bottom: 0;
  padding-bottom: 20px;
  background-color: #f6f6f6;
  .payBtn {
    margin-top: auto;
    margin: 0 auto;
    width: 690px;
    height: 70px;
    background: #b4272b;
    border-radius: 6px;
    font-size: 28px;
    color: #ffffff;
  }
}

.paymentInfoBox {
  width: 690px;
  background: #ffffff;
  border-radius: 6px;
  margin: 20px auto 45px;
  padding: 50px 30px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .bankImg {
    width: 262px;
    height: 44px;
    margin-bottom: 63px;
  }

  .remittanceBank {
    display: flex;
    align-items: flex-end;
    width: 100%;
    padding-bottom: 36px;
    border-bottom: solid 1px #dfdfdf;

    .left {
      flex: 1;

      p {
        width: 100%;
        display: flex;
        align-items: flex-start;
        line-height: 24px;
        margin-bottom: 22px;

        &:last-child {
          margin-bottom: 0;
        }

        span {
          font-size: 24px;
          font-weight: 400;
          line-height: 24px;
        }

        .title {
          color: #999999;
          white-space: nowrap;
        }
      }
    }

    .copyBrn {
      width: 240px;
      height: 60px;
      background: #b4272d;
      border-radius: 6px;
      font-size: 24px;
      color: #ffffff;
    }
  }

  .depositInformation {
    width: 100%;
    padding: 35px 0 0px;

    .minimumDepositAmount {
      margin-bottom: 42px;

      span {
        font-size: 24px;
        font-weight: 400;
        line-height: 24px;
      }

      .title {
        color: #999999;
      }

      b {
        font-size: 36px;
        font-weight: 500;
        color: #b4272b;
      }
    }

    .paymentFormBox,
    :deep(.paymentFormBox) {
      .van-cell-group {
        margin: 0;
      }

      .van-cell {
        padding: 0;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        &::after {
          display: none;
        }

        border: none;

        .van-cell__title {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          padding-top: 11px;
          width: 200px;
          font-size: 28px;

          font-weight: 400;
          color: #999999;

          &::before {
            content: "*";
            width: 20px;
            color: #b4272b;
          }
          label {
            white-space: pre-wrap;
          }
        }

        .van-field__body {
          textarea {
            width: 100%;
            height: 138px;
            background: #ffffff;
            border: 1px solid #dfdfdf;
            border-radius: 6px;
            padding: 15px 10px;
            font-size: 28px;
            font-weight: 400;
            color: #000000;
            line-height: 32px;
          }
        }
      }

      input[type="text"] {
        height: 70px;
        background: #ffffff;
        border: 1px solid #dfdfdf;
        border-radius: 6px;
        font-size: 28px;
        font-weight: 400;
        color: #000000;
      }

      .paymentAmount {
        position: relative;

        input {
          padding-right: 50px;
        }

        .yuan {
          position: absolute;
          right: 22px;
          font-size: 28px;
          color: #000000;
          border: none;
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          justify-content: center;
          align-items: center;
          width: 25px;
        }
      }

      .optionalField {
        .van-cell__title {
          &::before {
            content: "";
          }
        }
      }
    }

    .RemittanceNotes {
      padding: 30px 0 0;
      width: 568px;

      h3 {
        font-size: 28px;
        font-weight: 600;
        color: #010101;
        margin-bottom: 29px;
      }

      p {
        font-size: 24px;
        line-height: 1.5;
        font-weight: 400;
        color: #010101;
        margin-bottom: 5px;
      }

      p.importantReminder {
        color: #b4272d;
        margin-top: 25px;
      }
    }
  }
}

:deep(.van-calendar__confirm) {
  span {
    font-size: 24px;
  }
}
</style>
