<!-- 支付成功页面 -->
<template>
  <div>
    <div class="paymentSuccessBox">
      <img
        class="successIcon"
        :src="require('@/assets/icon/chenggong.png')"
        alt=""
      />
      <p
        class="successFont"
        
      >
      <p> 入金報告操作成功</p> 
      <p>※ご入金がまだ完了しておりません。お客様がご指定した銀行に入カされた金額と口座名義でお振込みをお願いいたします。</p>
      <p>※入金反映には少々お時間が掛かります。何度も同じ操作を繰り返されますと、入金確認待ち金額が累積されますので、実際の入金額と一致せず、調査に時間が掛かってしまいますので、１回のみでお願いします。</p>



      </p>

      <div class="transactionNumber">
        <p>
          <span class="title"> {{ $fanyi("入金金额") }}: </span>
          <span class="con">
            <span style="color: #b4272b; font-size: 28px"
              >{{ $route.query.pay_amount_jpy }}
            </span>
            {{ "円" }}</span
          >
        </p>
        <p>
          <span class="title"> {{ $fanyi("交易流水号") }}: </span>
          <span class="con">{{ $route.query.serial_number }}</span>
        </p>
      </div>

      <button
        class="operationButton red"
        @click="$fun.routerToPage('/user/purse')"
      >
        {{ $fanyi("回到钱包") }}
      </button>
      <!-- <button class="operationButton red" @click="$fun.routerToPage('/user/index')">
        {{ $fanyi("回到个人中心") }}
      </button>
      <button class="operationButton" @click="$fun.routerToPage('/')">
        {{ $fanyi("回到首页") }}
                                                                          </button> -->
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref } from "vue";
const { proxy } = getCurrentInstance();
//------------------------ data -----------------------------------------
//------------------------ pageLoad -------------------------------------
//------------------------ methods --------------------------------------
defineExpose({});
</script>
<style lang='scss' scoped='scoped'>
@import "@/css/mixin.scss";

.paymentSuccessBox {
  width: 690px;
  background: #ffffff;
  border-radius: 6px;
  padding: 60px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto 0;

  .successIcon {
    width: 80px;
    height: 80px;
    margin-bottom: 41px;
  }

  .successFont {
    font-size: 32px;
    color: #000000;
    line-height: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    p{
      font-size: 27px;
      line-height: 35px;
      text-align: left;
      &:first-child{
        font-size: 32px;
        margin-bottom: 15px;
        font-weight: bold;
        text-align: center;
      }
    }
  }

  .transactionNumber {
    margin-top: 60px;
    padding: 42px 0px;
    width: 100%;

    p {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 42px;

      &:last-child {
        margin-bottom: 0;
      }

      .title {
        font-size: 28px;
        color: #000000;

        text-align: left;
      }

      .con {
        font-size: 28px;
        color: #000000;
      }
    }
  }

  .operationButton {
    padding: 0 50px;
    height: 70px;
    background: #ffffff;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    font-size: 28px;
    margin-top: 20px;
    color: #000000;
  }

  .operationButton.red {
    margin-top: 30px;
    background: #b4272b;
    color: #ffffff;
  }
}
</style>