<!--支付页面-->
<template>
  <div class="pageF6Color">
    <HeadGoBackVue iCustomizdde="true" :icon="operationSteps == 3">
      <template #default>
        <span> {{ $fanyi("充值") }} </span>
      </template>
    </HeadGoBackVue>
    <div class="pageF6ColorBox" v-if="operationSteps == 1">
      <div class="topUpAmount">
        <h5>{{ $fanyi("充值金额") }}</h5>
        <p>
          <input
            class="Pay"
            v-model="paymentPagePreviewData.bank_pay_amount_min"
            type="number"
            oninput="value=value.replace(/[^\d]/g,'')"
          /><span>円</span>
        </p>
      </div>
      <ul class="paymentMethods">
        <h5>{{ $fanyi("选择支付方式") }}</h5>
        <!-- <li @click="paymentMethodSelection(1)">
          <div class="payIcon">
            <img :src="require('@/assets/icon/yuezhifu.png')" alt="" />
          </div>
          <span>{{ $fanyi("余额支付") }}</span>
          <input type="checkbox" :checked="userSelectedPaymentMethod == 1" />
                                                                                                                                                                        </li> -->
        <li>
          <div class="payIcon">
            <img :src="require('@/assets/icon/yinhangzhifu.png')" alt="" />
          </div>
          <span>{{ $fanyi("银行支付") }}</span>
        </li>
        <li
          v-for="paymentMethodItem in payMethodListData"
          :key="paymentMethodItem.key"
          @click="paymentMethodSelection(paymentMethodItem.key)"
        >
          <div class="payIcon"></div>
          <span>{{ $fanyi(paymentMethodItem.value) }}</span>
          <input
            type="checkbox"
            :checked="userSelectedPaymentMethod == paymentMethodItem.key"
          />
        </li>
        <li @click="paymentMethodSelection(5)">
          <div class="payIcon">
            <img :src="require('@/assets/icon/payPal.png')" alt="" />
          </div>
          <span>{{ $fanyi("paypal支付") }}</span>
          <input type="checkbox" :checked="userSelectedPaymentMethod == 5" />
        </li>
      </ul>
      <button class="payBtn" @click="nextStep()">
        {{ $fanyi("跳转下一步") }}
      </button>
    </div>
    <confirmPayment @recharge="recharge" v-else-if="operationSteps == 2" />
    <paymentSuccess v-else-if="operationSteps == 3" />
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, watch } from "vue";
import HeadGoBackVue from "@/components/headGoBack";
import confirmPayment from "./confirmPayment";
import paymentSuccess from "./paymentSuccess.vue";
import { Toast } from "vant";

const { proxy } = getCurrentInstance();
//------------------------ data -----------------------------------------
const payMethodListData = ref(); //订单可选支付方式列表
const paymentPagePreviewData = ref({
  bank_pay_amount_min: "",
}); //支付页面预览数据
const operationSteps = ref(1); //页面状态 1=选择支付方式 2=确认付款 3=支付成功
const userSelectedPaymentMethod = ref(-1); // 用户选择的支付方式
const paymoney = ref("");

//------------------------ methods --------------------------------------\
//选择支付方式
const paymentMethodSelection = (i) => {
  if (userSelectedPaymentMethod.value == i) {
    userSelectedPaymentMethod.value = -1;
  } else {
    userSelectedPaymentMethod.value = i;
  }
};

// 获取订单可选支付方式列表
const listOfPaymentOptionsForOrder = () => {
  proxy.$api.payMethodList().then((res) => {
    ////console.log('事件名',res)
    if (res.code != 0) return this.$message.error(res.data.msg);
    //接下来的操作
    payMethodListData.value = res.data;
    //接口返回数据没有图标也没有分类,所以只取银行数据,余额支付和paypal是一直有的
    payMethodListData.value = payMethodListData.value.filter(
      (obj) => obj.is_bank != 0
    );
  });
};
listOfPaymentOptionsForOrder();

// 下一步
const nextStep = () => {
  if (userSelectedPaymentMethod.value == -1) {
    Toast(proxy.$fanyi("请输入充值金额并选择付款方式"));
  } else {
    if (paymentPagePreviewData.value.bank_pay_amount_min == "") {
      Toast(proxy.$fanyi("请输入充值金额并选择付款方式"));
      return;
    }
    if (userSelectedPaymentMethod.value == 5) {
      Toast.loading({
        duration: 0,
        message: proxy.$fanyi("加载中..."),
        forbidClick: true,
      });
      proxy.$api
        .payPalPayBalance({
          pay_amount: paymentPagePreviewData.value.bank_pay_amount_min,
        })
        .then((res) => {
          if (res.code != 0)
            return proxy.$message.error(
              proxy.$fanyi(res.msg) || proxy.$fanyi("请更换其他支付方式")
            );

          if (res.data.pay_url && res.data.pay_url.substr(0, 4) == "http") {
            location.href = res.data.pay_url;
          } else {
            proxy.$message.error(
              proxy.$fanyi(res.msg) || proxy.$fanyi("请更换其他支付方式")
            );
          }
          Toast.clear();
        });

      return;
    }

    operationSteps.value++;

    let querydata = JSON.parse(JSON.stringify(proxy.$route.query));
    querydata.step = operationSteps.value;
    querydata.userSelectedPaymentMethod = userSelectedPaymentMethod.value;
    proxy.$router.push({
      path: "/user/recharge",
      query: querydata,
    });
  }
};

// 信息填写完成后充值
const recharge = async (data) => {
  Toast.loading({
    duration: 0,
    message: proxy.$fanyi("加载中..."),
    forbidClick: true,
  });
  console.log(data);
  console.log(proxy.$route.query.userSelectedPaymentMethod);

  const res = await proxy.$api.capitalBankUpBalance({
    pay_bank_method: proxy.$route.query.userSelectedPaymentMethod,
    pay_reach_date: data.pay_reach_date,
    pay_name: data.pay_name,
    pay_amount: data.pay_amount,
    pay_remark: data.pay_remark,
  });
  Toast.clear();
  if (res.code !== 0) {
    Toast.fail(proxy.$fanyi(res.msg));
    return;
  }
  let querydata = {};
  querydata.step = 3;
  querydata.pay_amount_jpy = res.data.pay_amount_jpy;
  querydata.serial_number = res.data.serial_number;
  proxy.$router.push({
    path: "/user/recharge",
    query: querydata,
  });
};
//------------------------ pageLoad -------------------------------------
watch(
  () => proxy.$route.query.step,
  (newValue) => {
    if (proxy.$route.query.step) {
      operationSteps.value = proxy.$route.query.step;
    } else {
      operationSteps.value = 1;
    }
    // orderPayment();
  },
  { immediate: true }
);
// getPaymentPagePreviewData();
defineExpose({ paymentPagePreviewData, userSelectedPaymentMethod, nextStep });
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.pageF6ColorBox {
  padding: 0px 30px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.topUpAmount {
  margin-top: 20px;
  padding: 60px 0;
  background: #ffffff;
  border-radius: 6px;

  h5 {
    font-size: 24px;
    text-align: center;
    line-height: 1;
    color: #999999;
    margin-bottom: 29px;
  }

  .Pay {
    width: 320px;
    border: none;
    font-size: 48px;
    height: 60px;
    line-height: 52px;
    font-weight: 600;
    color: #b4272b;
    text-align: center;
    border-radius: 0;
    padding: 0;
    // padding-bottom: 10px;
    border-bottom: 1px solid #000000;
  }

  p {
    text-align: center;
    font-size: 56px;
    color: #b4272b;
    font-weight: 400;

    span {
      font-size: 24px;
      font-weight: 400;
      margin-left: 8px;
    }
  }

  .paymentOrderNumber {
    margin-top: 29px;
    font-size: 24px;

    font-weight: 400;
    color: #000000;
    text-align: center;
    display: block;
  }
}

.paymentMethods {
  background: #ffffff;
  border-radius: 6px;
  background: #ffffff;
  margin-top: 15px;
  padding: 30px;

  h5 {
    font-size: 24px;
  }

  li {
    margin: 42px 0;

    &:last-child {
      margin-bottom: 0;
    }

    display: flex;
    align-items: center;

    .payIcon {
      width: 30px;
      flex: 0 0 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        margin-right: 20px;
      }
    }

    span {
      font-size: 28px;
      font-weight: 600;
    }

    input {
      pointer-events: none;
      margin-left: auto;
      border: solid 1px #dfdfdf;
      $size: 30px;
      flex: 0 0 $size;
      width: $size;
      height: $size;
      border-radius: 50%;
    }
  }
}

.payBtn {
  margin-top: auto;
  position: fixed;
  bottom: 50px;
  margin: 0 auto;
  width: 690px;
  height: 70px;
  background: #b4272b;
  border-radius: 6px;
  font-size: 28px;
  color: #ffffff;
}
</style>
